.project-link {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 720px;
}

.project-link__title {
  margin: 8px 0 0 0;
}
