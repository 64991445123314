body {
  background-image: url("/public/img.png");
  margin: 0;
}

* {
  font-family: Montserrat, Arial, sans-serif;
  box-sizing: border-box;
  color: #aee0ff;
}

a {
  color: #f9c846;
}

h1,
h2,
h3 {
  font-family:
    Libre Baskerville,
    serif;
}
