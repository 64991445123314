.App {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.heading {
  border-right: 2px solid #aee0ff;
  padding: 64px;
  min-height: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.content {
  flex: 3;
  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
